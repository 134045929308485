import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  children: JSX.Element;
}

function ProtectedRoute({ children }: ProtectedRouteProps) {
  const token = Cookies.get('access_token') || '';
  
  return token ? children : <Navigate to="/log-in" replace />;
}

export default ProtectedRoute;
