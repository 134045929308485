export const error = {
  required: 'This field is required',
  unknown: 'There\'s something wrong here',
  email: 'Incorrect email format. Try xxxxx@xxx.xx.',
  code: 'Code is not valid',
};

export const success = {
  codeSent: 'We\'ve sent a verification code to your email',
};

export const warning = {
  create: 'We’re creating your patient card. Staying here ensures everything processes smoothly. Thanks for your patience!', 
  reply: 'We’re generating a reply for you. Staying here ensures everything processes smoothly. Thanks for your patience!'
};
