import './SidebarErrorBlock.scss';

 
function SidebarErrorBlock() {
  
  return (
    <div className="sidebar-error-block">
      <div className="sidebar-error-block__title">
        Error
      </div>
      <div className="sidebar-error-block__details">
        The ID number you have entered does not exist in our database. 
        Please check the ID number and try again.
      </div>
    </div>
  );
}

export default SidebarErrorBlock;
