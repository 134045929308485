import { ForwardedRef, forwardRef } from 'react';
import { CustomButton } from 'components/Buttons';
import './RequiredModal.scss';

interface RequiredModalProps {
  onConfirm?: () => void;
 }
 
const RequiredModal = forwardRef((
  { onConfirm }: RequiredModalProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <div className="required-modal"
      ref={ref}
      tabIndex={-1}>
      <div className="required-modal__title">
      Unsaved Changes Warning
      </div>

      <div className="required-modal__content">
        Not all mandatory fields are filled. The case will not be saved. Do you want to proceed? 
      </div> 
      <div className="required-modal__buttons">
        
        <CustomButton
          text="OK"
          size="sm"
          variant="solid"
          colorScheme="success"
          onClick={onConfirm}
        />
      </div>

    </div>
  );
});

export default RequiredModal;

