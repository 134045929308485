import { AppDispatch, RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { CustomButton } from 'components/Buttons';
import ResendTimer from 'components/ResendTimer';
import TwoFactorCodeInput from '../TwoFactorCodeInput/TwoFactorCodeInput';
import { checkCodeThunk } from 'store/slices/appSlice';
import { forgotPasswordSMS} from 'api/userApi';
import { useState } from 'react';
import './TwoFactorCodeForm.scss';

interface TwoFactorCodeFormProps {
  onSubmit?: () => void; 
}

function TwoFactorCodeForm({ onSubmit }: TwoFactorCodeFormProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { email} = useSelector((state: RootState) => state.app.user);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [resetSignal, setResetSignal] = useState(false);
  const [code, setCode] = useState<string[]>(Array(6).fill(''));
  const [error, setError] = useState('');

  const handleChange = (newCode: string[]) => {
    setCode(newCode);
    setError('');
    
  };

  const handleResend = () => {
    if ( email) {
      forgotPasswordSMS({ email }); 
    }
    setError('');
    setIsResendDisabled(true);
    setResetSignal(true);
    setTimeout(() => setResetSignal(false), 100);
  };

  const handleTimerComplete = () => {
    setIsResendDisabled(false);
  };

  const submit = async ({ email, code }: { email: string; code: string }) => {
    try { 
      const response = await dispatch(checkCodeThunk({ email, code }));
      if (response?.meta?.requestStatus === 'fulfilled' && onSubmit) {
        onSubmit();
      } else {
        setError('Invalid code'); 
      }
    } catch (error) {
      setError('Something went wrong. Please try again.'); 
    }
  };
  


  return (
    <div className="two-factor-code-form">
      <div className="forgot-password2fa__header">
        The code was sent to your email. If you didn&apos;t receive the code, please, try again
      </div>
      <ResendTimer onTimerComplete={handleTimerComplete} resetSignal={resetSignal} />
      <TwoFactorCodeInput code={code} onChange={handleChange} error={error}/>
      {error && <div className="two-factor-code-form__error">{error}</div>}
      <button
        className="two-factor-code-form__button"
        onClick={handleResend}
        disabled={isResendDisabled}
      >
        Resend Email
      </button>
      <CustomButton
        text="Submit"
        size="xl"
        variant="solid"
        colorScheme="primary"
        disabled={code.some(num => num === '')}
        classes="two-factor-code-form__btn"
        onClick={() => {
          if(email)
            submit({ email, code: code.join('') });
        }}
      />
    </div>
  );
}

export default TwoFactorCodeForm;
