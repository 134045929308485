import axios from 'axios';

// Define the type for the error response
interface ErrorResponse {
    message: string;
    error: string;
    statusCode: number;
    success: boolean;
  }

export const handleError = (error: unknown): ErrorResponse => {
  if (axios.isAxiosError(error) && error.response) {
    const errorData: ErrorResponse = {...error.response.data, success: false}; 
    return errorData;
  } else {
    const errorData: ErrorResponse = {
      message: 'Unknown error occurred',
      error: 'Unknown Error',
      statusCode: 500,
      success: false
    }; 
    return errorData;
  }
};
