import { AuthForm } from 'components';
import { useState } from 'react';
import './SignUpPage.scss';

 
function SignUpPage() {
  const [page, setPage] = useState<'sign-up' | '2fa-code' | 'confirmation-request'>('sign-up');

  const onSubmitRegister = () => {
    setPage('confirmation-request');
  };

  const onSubmit2faCode = () => { 
    window.location.href = '/'; 
  };

  const getOnSubmitHandler = () => {
    switch (page) {
    case 'sign-up':
      return onSubmitRegister;
    case '2fa-code':
      return onSubmit2faCode;
    default:
      return;
    }
  };

  return (
    <div className="sign-up-page">
      <AuthForm page={page} onSubmit={getOnSubmitHandler()} />
    </div>
  );
}

export default SignUpPage;
