import { ForwardedRef, forwardRef } from 'react';
import { CustomButton } from 'components/Buttons';
import './DeleteModal.scss';

interface DeleteModalProps {
  onConfirm?: () => void;
  onCancel: () => void;
}

 
const DeleteModal = forwardRef((
  { onConfirm, onCancel }: DeleteModalProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
   
  return (
   
    <div
      className="delete-modal"
      ref={ref}
      tabIndex={-1}
    >
      <div className="delete-modal__title">
      Delete Case
      </div>

      <div className="delete-modal__content">
      Are you sure you want to delete this case?
      </div>
      <div className="delete-modal__buttons">
        <CustomButton
          text="No"
          size="sm"
          variant="ghost"
          colorScheme="secondary"
          onClick={onCancel}
        />
        <CustomButton
          text="Yes"
          size="sm"
          variant="solid"
          colorScheme="warning"
          onClick={onConfirm}
        />
      </div>

    </div>
  );
});

export default DeleteModal;
