import { AuthForm } from 'components';
import { useState } from 'react';
import './RecoveryPage.scss';
 
function RecoveryPage() {
  const [page, setPage] = useState<'forgot-pass-number' | 'forgot-pass-2fa' | 'forgot-pass-new-password' | 'forgot-pass-success'>('forgot-pass-number');

  const onSubmitRegister = () => {
    setPage('forgot-pass-2fa');
  };

  const onSubmit2faPhone = () => { 
    setPage('forgot-pass-new-password');
  };

  const onSubmit2faCode = () => {
    // window.location.href = '/log-in';
    setPage('forgot-pass-success');
  };


  const getOnSubmitHandler = () => {
    switch (page) {
    case 'forgot-pass-number':
      return onSubmitRegister;
    case 'forgot-pass-2fa':
      return onSubmit2faPhone;
    case 'forgot-pass-new-password':
      return onSubmit2faCode;
    default:
      return;
    }
  };
  
  return (
    <div className="recovery-page">
      <AuthForm page={page} onSubmit={getOnSubmitHandler()} />
    </div>
  );
}

export default RecoveryPage;
