import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'; 
import { createPatientApi, deletePatientApi, getChatsApi, getOneChatApi, updatePatientApi } from '../../api/chatApi';
import { checkCode } from '../../api/userApi'; 
 
export interface appState {
    loadingPage: boolean;
    loading: boolean;
    currentPage: 'dashboard' | 'faq';
    currentTab: 'view' | 'create' | 'edit' | 'settings';
    user: User;
    chats: Patient_card[];
    activeChat: Patient_card | null;
    demo: boolean;
    blockNavigation: boolean;
    pageBlock: string;
    saveModal: boolean;
}

const initialState: appState = {
  loadingPage: false,
  loading: false,
  currentPage: 'dashboard',
  currentTab: 'create',
  user: {
    user_id: '',
    email: '', 
    credits: 0,
    name: '',
  },
  chats: [],
  activeChat: null,
  demo: true,
  blockNavigation: false,
  pageBlock: '',
  saveModal: false,
  
};
 
export const checkCodeThunk = createAsyncThunk<SuccessCodeResponse | ErrorResponse, CheckCodeArgs>(
  'app/getUserData',
  async ({ email, code }: CheckCodeArgs, { rejectWithValue}) => {
    try {
      const res: SuccessCodeResponse | ErrorResponse = await checkCode({ email, code });
      if('success' in res && res.success) {

        return res;
      } else{
        return rejectWithValue(res);
      } 
    } catch (error: any) {
      const errorResponse: ErrorResponse = {
        success: false,
        error: error.response ? error.response.data : error.message,
        statusCode: error.response ? error.response.status : 500,
        message: error.response ? error.response.data : error.message
      };
      return rejectWithValue(errorResponse);
    }
  }
);


export const getChats = createAsyncThunk<Patient_card[] | ErrorResponse, string>(
  'app/getChats',
  async (user_id: string, { rejectWithValue }) => {
    try {
      const res = await getChatsApi(user_id); 
      return res;
    } catch (error: any) {
      const errorResponse: ErrorResponse = {
        success: false,
        error: error.response ? error.response.data : error.message,
        statusCode: error.response ? error.response.status : 500,
        message: error.response ? error.response.data : error.message,
      };
      return rejectWithValue(errorResponse);
    }
  }
);

export const getOneChat = createAsyncThunk<Patient_card[] | ErrorResponse, number>(
  'app/getOneChat',
  async (chat_id: number, { rejectWithValue }) => {
    try {
      const res = await getOneChatApi(chat_id); 
      return res;
    } catch (error: any) {
      const errorResponse: ErrorResponse = {
        success: false,
        error: error.response ? error.response.data : error.message,
        statusCode: error.response ? error.response.status : 500,
        message: error.response ? error.response.data : error.message,
      };
      return rejectWithValue(errorResponse);
    }
  }
);

export const createPatient = createAsyncThunk<CreateChat | ErrorResponse, CreateChat>(
  'app/createPatient',
  async (data: CreateChat, { rejectWithValue }) => {
    try {
      const res = await createPatientApi(data); 
      return res;
    } catch (error: any) {
      const errorResponse: ErrorResponse = {
        success: false,
        error: error.response ? error.response.data : error.message,
        statusCode: error.response ? error.response.status : 500,
        message: error.response ? error.response.data : error.message,
      };
      return rejectWithValue(errorResponse);
    }
  }
);

export const updatePatient = createAsyncThunk<UpdateChatResponse | ErrorResponse, UpdateChat >(
  'app/updatePatient',
  async (data: UpdateChat, { rejectWithValue }) => {
    try {
      const res = await updatePatientApi(data); 
      return res;
    } catch (error: any) {
      const errorResponse: ErrorResponse = {
        success: false,
        error: error.response ? error.response.data : error.message,
        statusCode: error.response ? error.response.status : 500,
        message: error.response ? error.response.data : error.message,
      };
      return rejectWithValue(errorResponse);
    }
  }
);


export const deletePatient = createAsyncThunk<UpdateChatResponse | ErrorResponse, DeletePatient >(
  'app/updatePatient',
  async (data: DeletePatient, { rejectWithValue }) => {
    try {
      const res = await deletePatientApi(data); 
      return res;
    } catch (error: any) {
      const errorResponse: ErrorResponse = {
        success: false,
        error: error.response ? error.response.data : error.message,
        statusCode: error.response ? error.response.status : 500,
        message: error.response ? error.response.data : error.message,
      };
      return rejectWithValue(errorResponse);
    }
  }
);

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setPageBlock: (state, action: PayloadAction<string>) => {
      state.pageBlock = action.payload;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    endLoading: (state) => {
      state.loading = false;
    },
    startLoadingPage: (state) => {
      state.loadingPage = true;
    },
    endLoadingPage: (state) => {
      state.loadingPage = false;
    },
    setUserId: (state, action) => {
      state.user.user_id = action.payload;
    }, 
    setUserEmail: (state, action) => {
      state.user.email = action.payload;
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setDemo: (state, action) => {
      state.demo = action.payload;
    },
    setChats: (state, action) => {
      state.chats = action.payload;
    },
    setActiveChat: (state, action) => {
      state.activeChat = action.payload;
    },
    setBlockNavigation: (state, action) => {
      state.blockNavigation = action.payload;
    },
    setSaveModal: (state, action) => {
      state.saveModal = action.payload;
    },
    setStatus: (state, action) => {
      if(state.activeChat)
        state.activeChat.status = action.payload;
    },
    addMessage: (state, action) => {  
      if(state.activeChat) {
        state.activeChat.messages = [...state.activeChat.messages, action.payload];
      }
    },
    replaceMessages: (state, action) => { 
      if(state.activeChat) {
        state.activeChat.messages = action.payload;
      }
    },
    replaceLastMessage: (state, action) => {
      if(state.activeChat) {
        state.activeChat.messages[state.activeChat.messages.length - 1] = action.payload;
      }
    },
    removeRegenerate: (state) => {
      if(state.activeChat) {
        state.activeChat.regenerate = false;
      }
    },
    clearData: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkCodeThunk.pending, (state) => {
        state.loadingPage = true;
      })
      .addCase(checkCodeThunk.fulfilled, (state, action: any) => {
        state.user = {
          email: action.payload.email, 
          user_id: action.payload.user_id,
          credits: 0,
          name: action.payload.name
        };
        state.loadingPage = false;
      })
      .addCase(checkCodeThunk.rejected, (state) => {
        state.loadingPage = false;
        state.user.user_id= '';
        
      })
      .addCase(getChats.pending, (state) => {
        state.loadingPage = true;
      })
      .addCase(getChats.fulfilled, (state, action: any) => {
        state.chats = action.payload.chats;
        state.user.credits = action.payload.credits;
        state.activeChat = action.payload.chats[0] ? action.payload.chats[0] : null;
        state.currentTab = action.payload.chats[0] ? 'view' : 'create';
        state.loadingPage = false;
      })
      .addCase(getChats.rejected, (state) => {
        state.loadingPage = false;
        state.chats = [];
      })
      .addCase(createPatient.pending, (state) => {
        state.loadingPage = true;
      })
      .addCase(createPatient.fulfilled, (state, action: any) => {
        if(!action.payload.id) {
          state.loadingPage = false;
        } else{
          state.chats = [...state.chats, action.payload];
          state.activeChat = action.payload;
          state.user.credits = action.payload.credits;
          state.loadingPage = false; 
        }
      })
      .addCase(createPatient.rejected, (state) => {
        state.loadingPage = false;
      })
      .addCase(updatePatient.pending, (state) => {
        state.loadingPage = true;
      })
      .addCase(updatePatient.fulfilled, (state, action: any) => {
        if(!action.payload.id) {
          state.loadingPage = false;
        } else{
          state.chats = state.chats.map((chat) => {
            if (chat.id === action.payload.id) {
              return action.payload;
            }
            return chat;
          });
          state.activeChat = action.payload;
          state.loadingPage = false;
        }
        state.loadingPage = false;
      })
      .addCase(updatePatient.rejected, (state) => {
        state.loadingPage = false;
      })
      .addCase(getOneChat.pending, (state) => {
        state.loadingPage = true;
      })
      .addCase(getOneChat.fulfilled, (state, action: any) => {
        state.activeChat = action.payload;
        state.loadingPage = false;
      })
      .addCase(getOneChat.rejected, (state) => {
        state.loadingPage = false;
      });
      
  }
});

export const {
  addMessage,
  startLoading,
  endLoading,
  startLoadingPage,
  endLoadingPage,
  replaceMessages,
  setUserId, 
  setUserEmail,
  setCurrentTab,
  setCurrentPage,
  setDemo,
  setChats,
  setActiveChat, 
  setBlockNavigation,
  setPageBlock,
  setSaveModal,
  setStatus,
  clearData,
  removeRegenerate,
  replaceLastMessage
  
} = appSlice.actions;

export default appSlice.reducer;
