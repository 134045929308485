import { validateEmail, validatePassword } from 'utils/validations';
import { CustomButton } from 'components/Buttons';
import LabeledInput from 'components/LabeledInput';
import { login } from 'api/userApi';
import { setUserEmail } from 'store/slices/appSlice';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import './LoginForm.scss'; 

interface LoginFormProps { onSubmit?: () => void;}

function LoginForm({ onSubmit }: LoginFormProps) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({
    email: '',
    password: ''
  });

  const handleSubmit = async () => {
    let valid = true;
    const newErrors = { email: '', password: ''};

    if (!validateEmail(email)) {
      newErrors.email = 'Incorrect email. Please try again.';
      valid = false;
    }

    if (!validatePassword(password)) {
      newErrors.password = 'Password must be at least 6 characters long, contain one uppercase letter, one lowercase letter, and one digit';
      valid = false;
    }

    setErrors(newErrors);

    if (valid && onSubmit) {
      const res = await login({ email, password });

      if('error' in res ) {
        setErrors({ email: res.message, password: '' });
      } else{
        setErrors({ email: '', password: '' });
        dispatch(setUserEmail(email));
        onSubmit();
      }
    }
  };


  return (
    <div className="login-form">
      <LabeledInput 
        page="log-in"
        type="email"
        text={email}
        error={errors.email}
        onChange={(text) => {
          setEmail(text);
        }}
      />
      <LabeledInput 
        page="log-in"
        type="password"
        text={password}
        error={errors.password}
        onChange={(text) => {
          setPassword(text);
        }}

      />
      <CustomButton
        text="Continue"
        size="xl"
        variant="solid" 
        colorScheme="primary"
        classes="login-form__btn"
        onClick={handleSubmit}
      />

      <div className="login-form__footer">
        <span>Don’t have an account?</span>
        <a className="login-form__footer__link" href="/sign-up">Sign up</a>
      </div>


    </div>
  );
}

export default LoginForm;
