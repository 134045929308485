import ForgotPassword2fa from 'components/ForgotPassword2fa';
import ForgotPasswordNewPass from 'components/ForgotPasswordNewPass';
import ForgotPasswordNumberForm from 'components/ForgotPasswordNumberForm';
import LoginForm from 'components/LoginForm/LoginForm';
import { ReactComponent as Logo } from 'assets/images/solar_health-linear.svg';
import PasswordResetSuccess from 'components/PasswordResetSuccess';
import RegisterForm from 'components/RegisterForm';
import { ReactComponent as SuccessIcon } from 'assets/images/success-icon.svg';
import TwoFactorCodeForm from 'components/TwoFactorCodeForm'; 
import './AuthForm.scss'; 

interface AuthFormProps { page: string; onSubmit?: () => void;}

const renderForm = (page: string, onSubmit?: () => void) => {
  switch (page) {
  case 'log-in' :
    return(
      <>
        <div className="auth-form__container__title">Log In</div>
        <LoginForm onSubmit={onSubmit}/>
      </> 
    );
  case 'sign-up' :
    return (
      <>
        <div className="auth-form__container__title">Create an account and request access</div>
        <RegisterForm onSubmit={onSubmit}/>
      </>
    );
  case 'forgot-pass-number':
    return (
      <>
        <div className="auth-form__container__title">Forgot password</div>
        <ForgotPasswordNumberForm onSubmit={onSubmit}/>
      </>
    );
  case 'forgot-pass-2fa':
    return (
      <>
        <div className="auth-form__container__title">Forgot password</div>
        <ForgotPassword2fa 
          onSubmit={onSubmit}
        />
      </>
    );
  case 'forgot-pass-new-password':
    return (
      <>
        <div className="auth-form__container__title">Forgot password</div>
        <ForgotPasswordNewPass onSubmit={onSubmit} />
      </>
    );
  case 'forgot-pass-success':
    return (
      <>
        <SuccessIcon className="password-reset-success__icon" style={{marginBottom: '12px'}} />
        <div className="auth-form__container__title">Password Reset</div>
        <PasswordResetSuccess text={'Sign in now'}/>
      </>
    );
  case '2fa-code':
    return (
      <>
        <div className="auth-form__container__title">Two-factor authentication</div>
        <TwoFactorCodeForm onSubmit={onSubmit}/>
      </>
    );
  
  
  case 'confirmation-request':
    return (
      <>
        <SuccessIcon className="password-reset-success__icon" style={{marginBottom: '12px'}} />
        <div className="auth-form__container__title">Your request has been sent </div>
        <div className="auth-form__container__text">
          Usually, it takes up to a couple of hours to review the request.
          Please Log in later to check your access using the credentials you provided during the request.</div>
        <PasswordResetSuccess text={'To Log in'}/>
      </>
    );

  }
};

function AuthForm({ page, onSubmit }: AuthFormProps) {
  
  return (
    <div className="auth-form">
      <div className="auth-form__top">
        <Logo className="auth-form__top__logo" />
        <div className="auth-form__top__title">CareAid</div>
      </div>
      <div className="auth-form__container">
        {
          renderForm(page, onSubmit)
        }
        
      </div>

    </div>
  );
}

export default AuthForm;
