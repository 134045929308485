import React, { useRef } from 'react';
import './TwoFactorCodeInput.scss';

interface TwoFactorCodeInputProps {
  code: string[];
  error?: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (code: string[]) => void;
}

function TwoFactorCodeInput({ error, code, onChange }: TwoFactorCodeInputProps) {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (/^\d?$/.test(value)) { // Only allow digits
      const newCode = [...code];
      newCode[index] = value;
      onChange(newCode);

      setTimeout(() => {
        if (value && index < 5) {
          inputRefs.current[index + 1]?.focus();
        }
      }, 0);

    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, index: number) => {
    const paste = e.clipboardData.getData('text');
    const newCode = [...code];
    if (/^\d+$/.test(paste)) { // Check if the pasted content is all digits
      for (let i = 0; i < paste.length && index + i < newCode.length; i++) {
        newCode[index + i] = paste[i];
      }
      onChange(newCode);
      setTimeout(() => {
        inputRefs.current[Math.min(index + paste.length, newCode.length - 1)]?.focus();
        e.preventDefault();
      }, 0);
    
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      inputRefs.current[index - 1]?.focus(); 
    }
  };

  return (
    <div className="two-factor-code-input">
      {code.map((num, index) => (
        <input
          key={index}
          type="text"
          inputMode="numeric"
          maxLength={1}
          value={num}
          onChange={(e) => handleChange(e, index)}
          onPaste={(e) => handlePaste(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          disabled={index !== 0 && !code[index - 1]}
          ref={el => (inputRefs.current[index] = el)}
          className={`two-factor-code-input__input ${error ? ' two-factor-code-input__input--error' : ''}`}
        />
      ))}
    </div>
  );
}

export default TwoFactorCodeInput;
