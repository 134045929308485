import React, { useState } from 'react';
import { cardFormInputs as typeTexts } from '../../data/typeTexts';
import './CardFormInput.scss';

interface CardFormInputProps {  
  type: 'patientId' | 'age' | 'complaint' | 'weight' | 'height';
  required?: boolean;
  value: string | number;
  disabled?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const unitHelper = (type: string, value: string | number) => {
  if (type === 'age' && typeof value === 'string' && value.split(' ').length > 1) {
    return value.split(' ')[1];
  }
  return 'year';
};

function CardFormInput({ disabled, type, required, value, onChange }: CardFormInputProps) {

  const [activeSelector, setActiveSelector] = useState(unitHelper(type, value) === 'year' ? 'year' : 'month');

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((type === 'weight' || type === 'patientId') && (e.key === 'e' || e.key === 'E')) {
      e.preventDefault();
    }
    if (type === 'age' && ((!/^\d$/.test(e.key) && e.key !== 'Backspace') || e.key === ' ' || e.key === '.' || e.key === 'Spacebar')) {
      e.preventDefault();
    }
    
  };

  return (
    <div className="card-form-input">
      <div className="card-form-input__label">
        {typeTexts[type].label} {required && <span>*</span>}
        {typeTexts[type].selector && (
          <div className="card-form-input__selector">
            <div 
              className={'card-form-input__selector__selector-btn' + (activeSelector === 'year' ? ' active' : '')}
              onClick={() => setActiveSelector('year')}
            >
              Year
            </div>
            <div
              className={'card-form-input__selector__selector-btn' + (activeSelector === 'month' ? ' active' : '')} 
              onClick={() => setActiveSelector('month')}
            >
              Month
            </div>
          </div>
        )}
      </div>
      <input
        className="card-form-input__input"
        type={type === 'complaint' || type === 'age' ? 'text' : 'number'}
        disabled={disabled}
        value={(type === 'age' && typeof(value) === 'string') ? value.split(' ')[0] : value}
        style={{ background: value ? '#D8D8FE' : '' }}
        onChange={(e) => {
          if (type === 'age') {
            onChange({
              ...e,
              target: {
                ...e.target,
                value: e.target.value + ' ' + activeSelector,
              },
            } as React.ChangeEvent<HTMLInputElement>);
          } else {
            onChange(e);
          }
        }}        
        onKeyDown={handleKeyDown}
        inputMode={type === 'complaint' ? 'text' : 'numeric'}
        pattern={type === 'complaint' ? undefined : '[0-9]*'}
      />
    </div>
  );
}

export default CardFormInput;
