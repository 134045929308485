import { ReactComponent as BackArrow } from '../../assets/images/back-arrow.svg';
import { FaqListItem } from 'components';
import { faqData } from 'data/faqData';
import { useNavigate } from 'react-router-dom';
import './FaqPage.scss';

 
function FaqPage() {
  const navigate = useNavigate();
  return (
    <div className="faq-page">
      <BackArrow className="faq-page__back-arrow" onClick={() =>navigate('/')}/>
      <div className="faq-page__top-cont">
        
        <div className="faq-page__top-cont__title-block">
          <div className="faq-page__top-cont__title-block__title">FAQ</div>
          <div className="faq-page__top-cont__title-block__sub-title">Your Guide to Using <span>CareAid</span></div>
        </div>
      </div>
      <div className="faq-page__bottom-cont">
        <div className="faq-page__bottom-cont__list">
          {faqData.map((faq, index) => (
            <FaqListItem 
              key={index} 
              open={faq.open} 
              question={faq.question} 
              answer={faq.answer} 
            />
          ))}
        </div>
      </div>

    </div>
  );
}

export default FaqPage;
