/* eslint-disable react-hooks/exhaustive-deps */
import { AppDispatch, RootState } from 'store';
import { ModalWrapper, RequiredModal, SaveModal } from 'components';
import { createPatient, setBlockNavigation, setCurrentTab, setSaveModal } from 'store/slices/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import CardFormDropdown from 'components/CardFormDropdown';
import CardFormInput from 'components/CardFormInput';
import CardFormTextarea from 'components/CardFormTextarea';
import { CustomButton } from 'components/Buttons';
import { initialState } from 'utils/data'; 
import { toast } from 'react-toastify';
import './PatientCreateTab.scss'; 
 
function PatientCreateTab( ) { 
  const dispatch: AppDispatch = useDispatch();
  const { user_id, credits } = useSelector((state: RootState) => state.app.user);
  const { saveModal, blockNavigation, chats } = useSelector((state: RootState) => state.app);
  const [requiredModal, setRequiredModal] = useState(false);
  const [patientId, setPatientId] = useState(initialState.patientId);
  const [age, setAge] = useState(initialState.age);
  const [gender, setGender] = useState< '' | 'Male' | 'Female' >('');
  const [weight, setWeight] = useState(initialState.weight);
  const [height, setHeight] = useState(initialState.height);
  const [complaint, setComplaint] = useState(initialState.complaint);
  const [illnessHistory, setIllnessHistory] = useState(initialState.illnessHistory);
  const [medicalHistory, setMedicalHistory] = useState(initialState.medicalHistory);
  const [medications, setMediactions] = useState(initialState.medications);
  const [ allergies, setAllergies] = useState(initialState.allergies);
  const [ socialHistory, setSocialHistory] = useState(initialState.socialHistory);
  const [familyHistory, setFamilyHistory] = useState(initialState.familyHistory);
  const [ systemsReview, setSystemsReview] = useState(initialState.systemsReview);
  const [ physicalExam, setPhysicalExam] = useState(initialState.physicalExam);
  const [ labResults, setLabResults] = useState(initialState.labResults);
  const [ additionalNotes, setAdditionalNotes] = useState(initialState.additionalNotes);

  const checkForRequiredFields = () => {
    return patientId.trim() !== '' && 
           age.trim() !== '' && 
           gender.trim() !== '' && 
           complaint.trim() !== '' && 
           weight.trim() !== '' && 
           height.trim() !== '' && 
           illnessHistory.trim() !== '' && 
           medicalHistory.trim() !== '' && 
           medications.trim() !== '' && 
           allergies.trim() !== '' && 
           socialHistory.trim() !== '' && 
           familyHistory.trim() !== '' && 
           systemsReview.trim() !== '' && 
           physicalExam.trim() !== '' && 
           labResults.trim() !== '' && 
           additionalNotes.trim() !== '';
  };
  
  const onCloseModal = () => {
    dispatch(setSaveModal(false));
    dispatch(setBlockNavigation(false));
  };

  const hasChanges = () => {
    return (
      initialState.patientId !== patientId ||
      initialState.age !== age ||
      initialState.gender !== gender ||
      initialState.complaint !== complaint ||
      initialState.weight !== weight ||
      initialState.complaint !== complaint ||
      initialState.illnessHistory !== illnessHistory ||
      initialState.medicalHistory !== medicalHistory ||
      initialState.medications !== medications ||
      initialState.allergies !== allergies ||
      initialState.socialHistory !== socialHistory ||
      initialState.familyHistory !== familyHistory ||
      initialState.systemsReview !== systemsReview ||
      initialState.physicalExam !== physicalExam ||
      initialState.labResults !== labResults ||
      initialState.additionalNotes !== additionalNotes
    );
  };

  useEffect(() => {
    dispatch(setSaveModal(false));
  }, []);

  useEffect(() => {
    dispatch(setBlockNavigation(hasChanges()));
  }, [patientId, age, gender, complaint, illnessHistory, medicalHistory, medications, allergies]);

  const convertToMultilineArray = (input: string): string[] => {
    return input.split('\n').filter(line => line.trim() !== '');
  };

  const checkForExistingPatient = () => {
    return chats.some(chat => chat.patientId === patientId);
  };

  const handleCreate = async () => { 
    if (!checkForRequiredFields()) {
      setRequiredModal(true);
      return;
    }
    if (checkForExistingPatient()) {
      notify();
      return;
    }
    await dispatch(createPatient({
      user_id: user_id,
      patientId: patientId,
      age: age,
      gender: gender,
      complaint: complaint,
      weight: weight,
      height: height,
      illnessHistory: convertToMultilineArray(illnessHistory),
      medicalHistory: convertToMultilineArray(medicalHistory),
      medications: convertToMultilineArray(medications),
      allergies: convertToMultilineArray(allergies), 
      socialHistory,
      familyHistory,
      systemsReview,
      physicalExam,
      labResults,
      additionalNotes,
      emr_link: '',
      messages: []
    })); 
    dispatch(setSaveModal(false));
    dispatch(setBlockNavigation(false));
    dispatch(setCurrentTab('view'));
  };

  const notify = () => toast('Patient with this ID already exists', {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    closeButton: false, // Remove the close icon
    theme: 'colored',
    style: {
      width: '238px',
      background: '#FFE4E5',
      color: '#050505',
      fontSize: '13px',
      fontWeight: '500',
      border: '1px solid #FE3A3F',
      lineHeight: '16px',
    },
  });
  
  return (
    <div className="patient-create-tab">
      <div className="patient-create-tab__header">
        <div>Patient Card </div>
       
      </div>
      <div className="patient-create-tab__content">
        <div className="patient-create-tab__content__block">
          <div className="patient-create-tab__content__block__section">
            <CardFormInput 
              type="patientId"
              value={patientId}
              required={true} 
              onChange={(val) => setPatientId(val.target.value)}
            />
            <CardFormInput 
              type="age"
              value={age}
              required={true} 
              onChange={(val) => setAge(val.target.value)}
            />
            <CardFormDropdown
              page="patient-create-tab" 
              value={gender}  
              onChange={(val) => setGender(val)}
            />
            <CardFormInput 
              type="weight"
              value={weight}
              required={true} 
              onChange={(val) => setWeight(val.target.value)}
            />
            <CardFormInput 
              type="height"
              value={height}
              required={true} 
              onChange={(val) => setHeight(val.target.value)}
            />
          </div>

          <div className="patient-create-tab__content__block__section">
            <CardFormTextarea
              page="patient-create-tab"
              type="complaint"
              value={complaint}
              onChange={(val) => setComplaint(val.target.value)}
            />
          </div>
          <div className="patient-create-tab__content__block__section">
            <CardFormTextarea
              page="patient-create-tab"
              type="illnessHistory"
              value={illnessHistory}
              onChange={(val) => setIllnessHistory(val.target.value)}
            />  
          </div>
          <div className="patient-create-tab__content__block__section">
            <CardFormTextarea
              page="patient-create-tab"
              type="medicalHistory"
              value={medicalHistory}
              onChange={(val) => setMedicalHistory(val.target.value)}
            />
          </div>
          <div className="patient-create-tab__content__block__section">
            <CardFormTextarea
              page="patient-create-tab"
              type="medications"
              value={medications}
              onChange={(val) => setMediactions(val.target.value)}
            />   
          </div>
          <div className="patient-create-tab__content__block__section">
            <CardFormTextarea
              page="patient-create-tab"
              type="allergies"
              value={allergies}
              onChange={(val) => setAllergies(val.target.value)}
            />
          </div>
        </div>
        <div className="patient-create-tab__content__block">
          <div className="patient-create-tab__content__block__section">
            <CardFormTextarea
              page="patient-create-tab"
              type="socialHistory"
              value={socialHistory}
              onChange={(val) => setSocialHistory(val.target.value)}
            />
          </div>
          <div className="patient-create-tab__content__block__section">
            <CardFormTextarea
              page="patient-create-tab"
              type="familyHistory"
              value={familyHistory}
              onChange={(val) => setFamilyHistory(val.target.value)}
            />
          </div>
          <div className="patient-create-tab__content__block__section">
            <CardFormTextarea
              page="patient-create-tab"
              type="systemsReview"
              value={systemsReview}
              onChange={(val) => setSystemsReview(val.target.value)}
            />
          </div>
          <div className="patient-create-tab__content__block__section">
            <CardFormTextarea
              page="patient-create-tab"
              type="physicalExam"
              value={physicalExam}
              onChange={(val) => setPhysicalExam(val.target.value)}
            />  
          </div>
          <div className="patient-create-tab__content__block__section">
            <CardFormTextarea
              page="patient-create-tab"
              type="labResults"
              value={labResults}
              onChange={(val) => setLabResults(val.target.value)}
            />
          </div>
          <div className="patient-create-tab__content__block__section">
            <CardFormTextarea
              page="patient-create-tab"
              type="additionalNotes"
              value={additionalNotes}
              onChange={(val) => setAdditionalNotes(val.target.value)}
            />
          </div>
        </div>

        <div className="patient-create-tab__button-create">
          <CustomButton 
            size="sm"
            text="Create" 
            disabled={!credits}
            onClick={handleCreate}
          />
        </div>

      </div>
      <ModalWrapper open={saveModal && blockNavigation} onClose={onCloseModal}>
        <SaveModal onCancel={onCloseModal} onConfirm={handleCreate} />
      </ModalWrapper>
      <ModalWrapper open={requiredModal} onClose={()=> setRequiredModal(false)}>
        <RequiredModal onConfirm={()=> setRequiredModal(false)} />
      </ModalWrapper>
    </div>
  );
}

export default PatientCreateTab;
