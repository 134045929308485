import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/search.svg';
import './SearchInput.scss';

interface SearchInputProps {
  searchValue: string;
  setSearchValue: (value: string) => void;
  error: boolean;
 }

function SearchInput({ 
  searchValue, 
  setSearchValue, 
  error
}: SearchInputProps) {
  
  return (
    <div className="search-input">
      <input
        type="text"
        className={`search-input__input ${error ? 'error' : ''}`}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder="Search"
      />
      {searchValue ? (
        <CloseIcon
          className="search-input__icon"
          onClick={() => setSearchValue('')}
        />
      ) : (
        <SearchIcon className="search-input__icon" />
      )}
      
    </div>
  );
}

export default SearchInput;
