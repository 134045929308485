import { useEffect, useState } from 'react';
import './ResendTimer.scss';

interface ResendTimerProps {
  onTimerComplete: () => void;
  resetSignal: boolean; 
}

function ResendTimer({ onTimerComplete, resetSignal }: ResendTimerProps) {
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (seconds > 0) {
      timer = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1);
      }, 1000);
    } else {
      onTimerComplete();
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [seconds, onTimerComplete]);

  useEffect(() => {
    if (resetSignal) {
      setSeconds(60);
    }
  }, [resetSignal]);

  return (
    <div className="resend-timer">
      {`00:${seconds.toString().padStart(2, '0')}`}
    </div>
  );
}

export default ResendTimer;
