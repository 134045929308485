import { ReactComponent as AlertIcon } from 'assets/images/shield-alert.svg';
import { ReactComponent as EyeIcon } from 'assets/images/eye-on.svg';
import { ReactComponent as EyeOffIcon } from 'assets/images/eye-off.svg';
import { labeledInputs as typeTexts} from '../../data/typeTexts';
import { useState } from 'react';
import './LabeledInput.scss';

interface LabeledInputProps {
  page?: string;
  type: 'email' | 'password' | 'confirmPass' | 'forgotPass' | 'phone' | 'phoneRecover' | 'code' | 'newPass' | 'name';
  text: string;
  error: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (text: string) => void;
  extraElement?: React.ReactNode;
 }

function LabeledInput({ page, type, text, error, onChange}: LabeledInputProps) {
  const { label, extraIcon } = typeTexts[type] ;
  const [showPassword, setShowPassword] = useState(false);
  
  return (
    <div className="labeled-input">
      <div className="labeled-input__label-block">
        <div className="labeled-input__label">{label}</div>
        <div 
          className="labeled-input__extra-label" 
          onClick={() => {
            if (page === 'log-in' && type === 'password') {
              window.location.href = '/pass-recovery';
            }
          }}
        >
          {page === 'log-in' && type === 'password' ? 'Forgot password?' : ''}
        </div>
      </div>

      <input
        type={type === 'password' || type === 'confirmPass' ? (showPassword ? 'text' : 'password') : 'text'}
        value={text} 
        placeholder={''}
        className={'labeled-input__input' + (error ? ' labeled-input__input--error' : '')}
        onChange={(e) => onChange(e.target.value)}
             
      />
      {extraIcon && (
        <div className="labeled-input__extra-icon" onClick={() => setShowPassword((val) => !val)}>
          {showPassword ? <EyeIcon /> : <EyeOffIcon /> }
        </div>
      )}
      <div className="labeled-input__error">
        {error && (
          <div className="labeled-input__error-cont">
            <div className="labeled-input__error-icon"><AlertIcon/></div>
            <div className="labeled-input__error-text">{error}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LabeledInput;

