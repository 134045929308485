import { MainPageContent, Sidebar } from 'components'; 
import { motion } from 'framer-motion'; 
import { switchingPages } from 'assets/animation';
import './MainPage.scss';

function MainPage() {
 
  
  return (
    <motion.section
      className="main-page"
      variants={switchingPages}
      initial="hidden"
      animate="visible"
      exit="exit"
      style={{ overflow: 'hidden' }}
    >
      <div className="main-page__container">
        <Sidebar />
        <MainPageContent />
        {/* <button onClick={onOpenModal}>Open Modal</button> */}

         
      </div>
    
    </motion.section>
  );
}

export default MainPage;
