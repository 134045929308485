/* eslint-disable react-hooks/exhaustive-deps */
import { AppDispatch, RootState } from 'store';
import { ModalWrapper, RequiredModal, SaveModal } from 'components';
import { setBlockNavigation, setCurrentTab, setSaveModal, updatePatient } from 'store/slices/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ReactComponent as Cancel } from 'assets/images/cancel.svg';
import CardFormDropdown from 'components/CardFormDropdown';
import CardFormInput from 'components/CardFormInput';
import CardFormTextarea from 'components/CardFormTextarea';
import { CustomButton } from 'components/Buttons';
import { toast } from 'react-toastify';
import './PatientEditTab.scss';


function PatientEditTab() {
  const dispatch = useDispatch<AppDispatch>();
  const { activeChat, saveModal, blockNavigation, chats } = useSelector((state: RootState) => state.app);

  const checkForRequiredFields = () => {
    return patientId.trim() !== '' && 
           age.trim() !== '' && 
           gender.trim() !== '' && 
           complaint.trim() !== '' && 
           weight.trim() !== '' && 
           height.trim() !== '' && 
           illnessHistory.trim() !== '' && 
           medicalHistory.trim() !== '' && 
           medications.trim() !== '' && 
           allergies.trim() !== '' && 
           socialHistory.trim() !== '' && 
           familyHistory.trim() !== '' && 
           systemsReview.trim() !== '' && 
           physicalExam.trim() !== '' && 
           labResults.trim() !== '' && 
           additionalNotes.trim() !== '';
  };
  
  const initialForm = {
    patientId: activeChat?.patientId || '',
    age: activeChat?.age || '',
    gender: activeChat?.gender || '',
    complaint: activeChat?.complaint || '', 
    weight: activeChat?.weight || '',
    height: activeChat?.height || '', 
    illnessHistory: activeChat?.illnessHistory || [],
    medicalHistory: activeChat?.medicalHistory || [],
    medications: activeChat?.medications || [],
    allergies: activeChat?.allergies || [],
    socialHistory: activeChat?.socialHistory || '',
    familyHistory: activeChat?.familyHistory || '',
    systemsReview: activeChat?.systemsReview || '',
    physicalExam: activeChat?.physicalExam || '',
    labResults: activeChat?.labResults || '',
    additionalNotes: activeChat?.additionalNotes || '',

  };
  const [requiredModal, setRequiredModal] = useState(false);
  const [patientId, setPatientId] = useState(initialForm.patientId);
  const [age, setAge] = useState(initialForm.age);
  const [gender, setGender] = useState(initialForm.gender);
  const [weight, setWeight] = useState(initialForm.weight);
  const [height, setHeight] = useState(initialForm.height);
  const [complaint, setComplaint] = useState(initialForm.complaint); 
  const [illnessHistory, setIllnessHistory] = useState(initialForm.illnessHistory.join('\n'));
  const [medicalHistory, setMedicalHistory] = useState(initialForm.medicalHistory.join('\n'));
  const [medications, setMediactions] = useState(initialForm.medications.join('\n'));
  const [ allergies, setAllergies] = useState(initialForm.allergies.join('\n'));
  const [ socialHistory, setSocialHistory] = useState(initialForm.socialHistory);
  const [familyHistory, setFamilyHistory] = useState(initialForm.familyHistory);
  const [ systemsReview, setSystemsReview] = useState(initialForm.systemsReview);
  const [ physicalExam, setPhysicalExam] = useState(initialForm.physicalExam);
  const [ labResults, setLabResults] = useState(initialForm.labResults);
  const [ additionalNotes, setAdditionalNotes] = useState(initialForm.additionalNotes);

  const [initialFormState, setInitialFormState] = useState(initialForm);
  const convertToMultilineArray = (input: string): string[] => {
    return input.split('\n').filter(line => line.trim() !== '');
  };

  useEffect(() => {
    setInitialFormState(initialForm);
  }, []);

  const onCloseModal = () => {
    dispatch(setSaveModal(false));
    dispatch(setBlockNavigation(false));
  };

  const hasChanges = () => {
    return (
      initialFormState.patientId !== patientId ||
      initialFormState.age !== age ||
      initialFormState.gender !== gender ||
      initialFormState.complaint !== complaint ||
      initialFormState.weight !== weight ||
      initialFormState.height !== height ||
      initialFormState.illnessHistory !== convertToMultilineArray(illnessHistory) ||
      initialFormState.medicalHistory !== convertToMultilineArray(medicalHistory) ||
      initialFormState.medications !== convertToMultilineArray(medications) ||
      initialFormState.allergies !== convertToMultilineArray(allergies) ||
      initialFormState.socialHistory !== socialHistory ||
      initialFormState.familyHistory !== familyHistory ||
      initialFormState.systemsReview !== systemsReview ||
      initialFormState.physicalExam !== physicalExam ||
      initialFormState.labResults !== labResults ||
      initialFormState.additionalNotes !== additionalNotes
    );
  };

  useEffect(() => {
    dispatch(setSaveModal(false));
  }, []);

  useEffect(() => {
    dispatch(setBlockNavigation(hasChanges())); 
  }, [patientId, age, gender, complaint, weight, height, illnessHistory, medicalHistory, medications, allergies, socialHistory, familyHistory, systemsReview, physicalExam, labResults, additionalNotes]);


  const onClose = () => { 
    dispatch(setBlockNavigation(false));
    dispatch(setCurrentTab('view'));
  };

  const checkForExistingPatient = () => {
    return chats.some(chat => chat.patientId === patientId && chat.id !== activeChat?.id);
  };

  const notify = () => toast('Patient with this ID already exists', {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    closeButton: false, // Remove the close icon
    theme: 'colored',
    style: {
      width: '238px',
      background: '#FFE4E5',
      color: '#050505',
      fontSize: '13px',
      fontWeight: '500',
      border: '1px solid #FE3A3F',
      lineHeight: '16px',
    },
  });

  const handleSave = async () => {
    if (!checkForRequiredFields()) {
      setRequiredModal(true);
      return;
    }
    if (checkForExistingPatient()) {
      notify();
      return;
    }
   
    await dispatch(updatePatient({
      chat_id:  activeChat?.id || 0,
      patientId: patientId,
      age: age,
      sex: gender,
      complaint: complaint, 
      weight: weight,
      height: height,
      illnessHistory: convertToMultilineArray(illnessHistory),
      medicalHistory: convertToMultilineArray(medicalHistory),
      medications: convertToMultilineArray(medications),
      allergies: convertToMultilineArray(allergies),
      socialHistory: socialHistory,
      familyHistory: familyHistory,
      systemsReview: systemsReview,
      physicalExam: physicalExam,
      labResults: labResults,
      additionalNotes: additionalNotes,
      emr_link: ''
    }));
    dispatch(setSaveModal(false));
    dispatch(setBlockNavigation(false));
    dispatch(setCurrentTab('view'));
  };


  return (
    <div className="patient-edit-tab">
      <div className="patient-edit-tab__header">
        <div>Patient Card </div>
        <div className="patient-edit-tab__header__close">
          <Cancel onClick={onClose} />
        </div>
      </div>
     
      <div className="patient-edit-tab__content">
        <div className="patient-edit-tab__content__block">
          <div className="patient-edit-tab__content__block__section">
            <CardFormInput 
              type="patientId"
              value={patientId}
              required={true} 
              onChange={(val) => setPatientId(val.target.value)}
            />
            <CardFormInput 
              type="age"
              value={age}
              required={true} 
              onChange={(val) => setAge(val.target.value)}
            />
            <CardFormDropdown
              page="patient-edit-tab" 
              value={gender}  
              onChange={(val) => setGender(val)}
            />
            <CardFormInput 
              type="weight"
              value={weight}
              required={true} 
              onChange={(val) => setWeight(val.target.value)}
            />
            <CardFormInput 
              type="height"
              value={height}
              required={true} 
              onChange={(val) => setHeight(val.target.value)}
            />
          </div>

          <div className="patient-edit-tab__content__block__section">
            <CardFormTextarea
              page="patient-edit-tab"
              type="complaint"
              value={complaint}
              onChange={(val) => setComplaint(val.target.value)}
            />
          </div>
          <div className="patient-edit-tab__content__block__section">
            <CardFormTextarea
              page="patient-edit-tab"
              type="illnessHistory"
              value={illnessHistory}
              onChange={(val) => setIllnessHistory(val.target.value)}
            />  
          </div>
          <div className="patient-edit-tab__content__block__section">
            <CardFormTextarea
              page="patient-edit-tab"
              type="medicalHistory"
              value={medicalHistory}
              onChange={(val) => setMedicalHistory(val.target.value)}
            />
          </div>
          <div className="patient-edit-tab__content__block__section">
            <CardFormTextarea
              page="patient-edit-tab"
              type="medications"
              value={medications}
              onChange={(val) => setMediactions(val.target.value)}
            />   
          </div>
          <div className="patient-edit-tab__content__block__section">
            <CardFormTextarea
              page="patient-edit-tab"
              type="allergies"
              value={allergies}
              onChange={(val) => setAllergies(val.target.value)}
            />
          </div>
        </div>
        <div className="patient-edit-tab__content__block">
          <div className="patient-edit-tab__content__block__section">
            <CardFormTextarea
              page="patient-edit-tab"
              type="socialHistory"
              value={socialHistory}
              onChange={(val) => setSocialHistory(val.target.value)}
            />
          </div>
          <div className="patient-edit-tab__content__block__section">
            <CardFormTextarea
              page="patient-edit-tab"
              type="familyHistory"
              value={familyHistory}
              onChange={(val) => setFamilyHistory(val.target.value)}
            />
          </div>
          <div className="patient-edit-tab__content__block__section">
            <CardFormTextarea
              page="patient-edit-tab"
              type="systemsReview"
              value={systemsReview}
              onChange={(val) => setSystemsReview(val.target.value)}
            />
          </div>
          <div className="patient-edit-tab__content__block__section">
            <CardFormTextarea
              page="patient-edit-tab"
              type="physicalExam"
              value={physicalExam}
              onChange={(val) => setPhysicalExam(val.target.value)}
            />  
          </div>
          <div className="patient-edit-tab__content__block__section">
            <CardFormTextarea
              page="patient-edit-tab"
              type="labResults"
              value={labResults}
              onChange={(val) => setLabResults(val.target.value)}
            />
          </div>
          <div className="patient-edit-tab__content__block__section">
            <CardFormTextarea
              page="patient-edit-tab"
              type="additionalNotes"
              value={additionalNotes}
              onChange={(val) => setAdditionalNotes(val.target.value)}
            />
          </div>
        </div>

        <div className="patient-edit-tab__button-create">
          <CustomButton 
            size="sm"
            text="Save" 
            onClick={handleSave}
          />
        </div>
        
      </div>
      <ModalWrapper open={saveModal && blockNavigation} onClose={onCloseModal}>
        <SaveModal onCancel={onCloseModal} onConfirm={handleSave} />
      </ModalWrapper>
      <ModalWrapper open={requiredModal} onClose={()=> setRequiredModal(false)}>
        <RequiredModal onConfirm={()=> setRequiredModal(false)} />
      </ModalWrapper>
    </div>
  );
}

export default PatientEditTab;
