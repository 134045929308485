import { cardFields as typeTexts } from '../../data/typeTexts';
import './CardField.scss';

interface CardFieldProps { 
  type: 'Patient ID' | 'Age:' | 'Weight:' | 'Height:' | 'Sex:' | 'Patient ID:' | 'Chief Complaint:';
  value: string | number;
}
 
function CardField({ type, value }: CardFieldProps) {
 
  return (
    <div className={`card-field + ${type === 'Chief Complaint:' ? 'col' : ''}`}>
      <div className="card-field__label">{type}</div>
      <div className="card-field__value">{value}
        {typeTexts[type]?.units ? typeTexts[type]?.units : '' }
      </div>
    </div>
  );
}

export default CardField;
