import { AuthForm } from 'components';
import { useState } from 'react';
import './LogInPage.scss';
 
function LogInPage() {
  const [page, setPage] = useState<'log-in' | '2fa-code'>('log-in');

  const onSubmitLogin = () => {
    setPage('2fa-code');
  };

  const onSubmit2faCode = () => {
    window.location.href = '/'; 
  };

  const getOnSubmitHandler = () => {
    switch (page) {
    case 'log-in':
      return onSubmitLogin;
    case '2fa-code':
      return onSubmit2faCode;
    default:
      return;
    }
  };
  return (
    <div className="log-in-page">
      <AuthForm page={page} onSubmit={getOnSubmitHandler()} />
    </div>
  );
}

export default LogInPage;
