import { FaqPage, LogInPage, MainPage, RecoveryPage, SignUpPage} from 'pages';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Socket, io } from 'socket.io-client';
import { createContext, useContext, useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion'; 
import Cookies from 'js-cookie';
import { ProtectedRoute } from 'components';
import { RootState } from 'store';
import { ToastContainer} from 'react-toastify';
import { useSelector } from 'react-redux';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';


const SocketContext = createContext<Socket | null>(null);
export const useSocket = () => useContext(SocketContext);

function App() {
  const location = useLocation();
  const token = Cookies.get('access_token');
  const user = useSelector((state: RootState) => state.app.user);
  const [newSocket, setNewSocket] = useState<Socket | null>(null);

  useEffect(() => {
    if(!user?.user_id) return;
    if(!token) return;

    const socket: Socket = io(`${process.env.REACT_APP_API_URL}`, {
      transports: ['websocket'],
      query: {
        id: user?.user_id,
        token: token,
      },
      reconnection: true, // Enable automatic reconnection
      reconnectionAttempts: Infinity, // Try to reconnect forever
      reconnectionDelay: 1000, // Initial delay of reconnection in milliseconds
      reconnectionDelayMax: 5000, // Maximum delay of reconnection
      randomizationFactor: 0.5 // Randomization factor for the reconnection delay
   
    });

    if (socket) {
      socket.on('connect', () => {
        setNewSocket(socket);
      });  
    }

    return () => {
      if (socket) {
        socket.off('connect'); 
        socket.disconnect();
      }
    };
  }, [user?.user_id, token]);

  return (
    <SocketContext.Provider value={newSocket}>
      <AnimatePresence initial={false} mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route
            path="sign-up"
            element={<SignUpPage />}
          />
          <Route
            path="log-in"
            element={<LogInPage />}
          />
          <Route
            path="pass-recovery"
            element={<RecoveryPage />}
          />
 
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <MainPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/faq"
            element={
              <ProtectedRoute>
                <FaqPage />
              </ProtectedRoute>
            }
          />
        
          <Route path="*" element={<Navigate to="/log-in" />} />
        </Routes>
      </AnimatePresence>
      <ToastContainer limit={1}/>
    </SocketContext.Provider>
  );
}

export default App;
