import { useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { RootState } from 'store'; 
import { useSelector } from 'react-redux';
import './ChatList.scss';

interface ChatMessage {
  id: number;
  text: string;
  role: string;
}

interface ChatListProps {
  topBlockHeight: number;
  rows: number;
}

function ChatList({ topBlockHeight, rows }: ChatListProps) {
  const messages = useSelector((state: RootState) => state.app.activeChat?.messages || []);
  const chatListRef = useRef<HTMLDivElement>(null);
  const userMessageRef = useRef<HTMLDivElement | null>(null);

  const handleResize = () => {
    return `calc(100vh - ${topBlockHeight}px - ${((rows - 1) * 20)}px - 67px - 64px - 100px)`;
  };

  useEffect(() => {
    if (userMessageRef.current ) {
      userMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      // Adjust the scroll position to add a 12px offset
      if (chatListRef.current) {

        // Calculate the desired scroll position with the offset
        const elementTop = userMessageRef.current.getBoundingClientRect().top;
        const containerTop = chatListRef.current.getBoundingClientRect().top;
        const offset = 12;

        // Set the scrollTop to position the element slightly higher
        chatListRef.current.scrollTop += elementTop - containerTop - offset;
      }
    }
  }, [messages]);

  useEffect(() => {
    if (userMessageRef.current) {
      userMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      // Adjust the scroll position to add a 12px offset
      if (chatListRef.current) {

        // Calculate the desired scroll position with the offset
        const elementTop = userMessageRef.current.getBoundingClientRect().top;
        const containerTop = chatListRef.current.getBoundingClientRect().top;
        const offset = 12;

        // Set the scrollTop to position the element slightly higher
        chatListRef.current.scrollTop += elementTop - containerTop - offset;
      }
    }
  }, [userMessageRef]);

  const renderMessage = (message: ChatMessage) => { 
    return (
      <div
        key={message.id}
        className={`chat-message ${message.role}`} 
        ref={userMessageRef}
      >
        <div><ReactMarkdown>{message.text.replace(/\n/g, '\n\n')}</ReactMarkdown></div>
      </div>
    );
  };

  return (
    <div
      className="chat-list"
      ref={chatListRef}
      style={{ maxHeight: handleResize(), minHeight: handleResize() }}
    >
      {messages?.map((message) => renderMessage(message))}
    </div>
  );
}

export default ChatList;
