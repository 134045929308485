import { useEffect, useRef, useState } from 'react';
import { ReactComponent as ChatLoader } from '../../assets/images/chat-loader.svg';
import { ReactComponent as SendIcon } from '../../assets/images/send.svg';
import './ChatInput.scss'; 

interface ChatInputProps {
  disabled?: boolean;
  rows: number;
  fullCard: boolean;
   // eslint-disable-next-line no-unused-vars
  setRows: (rows: number) => void;
  // eslint-disable-next-line no-unused-vars
  onSend: (message: string) => void;
}

function ChatInput({ fullCard, disabled, rows, setRows, onSend }: ChatInputProps) {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [message, setMessage] = useState(''); 

  const handleSend = () => {
    // if (message.trim()) {
    onSend(message);
    setMessage('');
    setRows(1);
    // }
  };

  useEffect(() => {
    if (ref.current && !fullCard) {
       
      ref.current.style.height = '0px';
      ref.current.style.height = `${ref.current.scrollHeight < 85 ? ref.current.scrollHeight : 84}px`;
      setRows((ref.current.scrollHeight / 20) < 4 ? ref.current.scrollHeight / 24 : 3);
      
    }
  }, [message, setRows, fullCard]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (disabled) return;
    if (e.key === 'Enter') {
      if (e.shiftKey) {  
        setMessage(message);
      } else { 
        handleSend();
        e.preventDefault();
      }
    }
  };

  return (
    <div className="chat-input">
      <div 
        className="chat-input__input-block"
        style={{ height: `${(rows * 20) + 24}px` }}
      >
        {disabled && <ChatLoader className="chat-input__input-block__loader" />}
        <textarea
          placeholder="Type your message here"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          rows={rows} // Adjust the rows as needed
          ref={ref}
          style={{ height: `${(rows * 24)}px` }}
        />
        {!disabled && 
          <div className="chat-input__input-block__send-icon" onClick={handleSend}>
            <SendIcon />
          </div>
        }
      </div>
      <div className="chat-input__footer">
        CareAid assists based on patient information but may make mistakes. Please make sure to verify important information, this is not an alternative to your medical decision making.
      </div>
    </div>
  );
}

export default ChatInput;
