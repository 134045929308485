import { ReactComponent as DeleteIcon } from 'assets/images/x.svg';
import './SidebarListItem.scss';

interface SidebarListItemProps { 
  patient: Patient_card;
  activeCard: number | null;
  pageBlock: string;
  navigateToPatient: (patient: Patient_card) => void;
  setDeleteId: (id: number) => void;
  setIsModal: (isModal: boolean) => void;
}

function SidebarListItem({patient, activeCard, pageBlock, navigateToPatient, setDeleteId, setIsModal }: SidebarListItemProps) {
  
  return (
    <div 
      key={patient.id} className={`sidebar-list__item ${patient.id === activeCard ? 'selected' : ''} ${pageBlock ? 'blocked' : ''}`}
      onClick={() => {
        if (pageBlock) return;
        navigateToPatient(patient);
      }}
    >
      <div className="sidebar-list__item__block">
        <div className="sidebar-list__item__title">
          ID: {patient.patientId}
        </div>
        <div className="sidebar-list__item__details">
          {patient.complaint}
        </div>
      </div>

      <div className="sidebar-list__item__delete">
        <DeleteIcon 
          className="sidebar-list__item__delete-icon" 
          onClick={(e) => {
            e.stopPropagation(); 
            setDeleteId(patient.id);
            setIsModal(true);
          }}
        />
      </div>
    </div>
  );
}

export default SidebarListItem;
