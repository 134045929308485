import { CustomButton } from 'components/Buttons';
import LabeledInput from 'components/LabeledInput';
import { RootState } from 'store';
import { forgotPasswordNewPass } from 'api/userApi'; 
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { validatePassword } from 'utils/validations';
import './ForgotPasswordNewPass.scss'; 

interface ForgotPasswordNewPassProps { 
  onSubmit?: () => void;
}

function ForgotPasswordNewPass({ onSubmit }: ForgotPasswordNewPassProps) {
  const { email } = useSelector((state: RootState) => state.app.user);
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({
    password: ''
  });

  const handleSubmit = async () => {
    let valid = true;
    const newErrors = { password: '' };

    if (!validatePassword(password)) {
      newErrors.password = 'Password must be at least 6 characters long, contain one uppercase letter, one lowercase letter, one digit, and one special character';
      valid = false;
    }
 
    setErrors(newErrors);

    if (valid && onSubmit) {
      if (!email) {
        return;
      }
      const res = await forgotPasswordNewPass({ email, password });
      if ('error' in res) {
        setErrors({ password: res.message });
      } else {
        onSubmit();
      }
    }
  };

  return (
    <div className="forgot-password-new-pass">
      <LabeledInput
        page="forgot-password-new-pass"
        type="newPass"
        text={password}
        error={errors.password}
        onChange={(text) => {
          setPassword(text);
          setErrors({ password: '' });
        }}
      />
      <CustomButton
        text="Submit"
        size="xl"
        variant="solid"
        colorScheme="primary"
        disabled={password === ''}
        classes="forgot-password-new-pass__btn"
        onClick={handleSubmit}
      />
    </div>
  );
}

export default ForgotPasswordNewPass;
