import { CustomButton } from 'components/Buttons';
import LabeledInput from 'components/LabeledInput';
import { forgotPasswordSMS } from 'api/userApi';  
import { setUserEmail } from 'store/slices/appSlice';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { validateEmail } from 'utils/validations';
import './ForgotPasswordNumberForm.scss';

interface ForgotPasswordNumberFormProps { onSubmit?: () => void; }

function ForgotPasswordNumberForm({ onSubmit }: ForgotPasswordNumberFormProps) {
  const dispatch = useDispatch();
  
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleEmailChange = (text: string) => {
    if (validateEmail(text)) {
      setError('');
    } else {
      setError('Invalid email');
    }
    setEmail(text);
  };

  const handleSendSMS = async () => {
    if (validateEmail(email)) {
      dispatch(setUserEmail(email));
      const res = await forgotPasswordSMS({ email });
      if('success' in res && res.success) { 
        dispatch(setUserEmail(email));
        onSubmit && onSubmit();
      } 
      if('error' in res) {
        setError(res.message);
      }
    }  
  };
  
  return (
    <div className="forgot-password-number-form">
      <LabeledInput 
        page="forgot-pass-number"
        type="email"
        text={email}
        error={error}
        onChange={handleEmailChange}
      />
      <CustomButton
        text="Send code"
        size="xl"
        variant="solid" 
        colorScheme="primary"
        classes="forgot-password-number-form__btn"
        onClick={handleSendSMS}
      />

      <div className="forgot-password-number-form__footer">
        <span>Don’t have an account?</span>
        <a className="forgot-password-number-form__footer__link" href="/sign-up">Sign up</a>
      </div>
    </div>
  );
}

export default ForgotPasswordNumberForm;
