import { cardFromTextareas as typeTexts } from '../../data/typeTexts';
import './CardFormTextarea.scss';

interface CardFormTextareaProps {
  page?: string;
  type: 'complaint' | 'illnessHistory' | 'medicalHistory' | 'medications' | 'allergies' | 'socialHistory' | 'familyHistory' | 'systemsReview' | 'physicalExam' | 'labResults' | 'additionalNotes';
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

function CardFormTextarea({ type, value, onChange }: CardFormTextareaProps) {

  return (
    <div className="card-form-textarea">
      <div className="card-form-textarea__label">{typeTexts[type].label} {typeTexts[type].required && <span>*</span>}</div>
      <textarea
        className="card-form-textarea__input"
        value={value}
        onChange={onChange}
        style={{ background: value.trim() ? '#D8D8FE' : '' }}
        rows={typeTexts[type].rows}  
        placeholder={typeTexts[type].placeholder}
      />
    </div>
  );
}

export default CardFormTextarea;
