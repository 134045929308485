import './CardList.scss';

interface CardListProps { 
  type: string;
  list: string[];
}

function CardList({
  type, list
}: CardListProps) {
  
  return (
    <div className="card-list">
      <div className="card-list__label">{type}</div>
      {
        list.map((item, index) => (
          item &&
            <div key={index} className="card-list__value">{'• ' + item}</div>
        ))
      }
    </div>
  );
}

export default CardList;
