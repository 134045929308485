import { forgotPasswordCheckCode, forgotPasswordSMS } from 'api/userApi'; 
import { CustomButton } from 'components/Buttons';
import ResendTimer from 'components/ResendTimer';
import { RootState } from 'store';
import TwoFactorCodeInput from '../TwoFactorCodeInput/TwoFactorCodeInput';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import './ForgotPassword2fa.scss';

interface ForgotPassword2faProps { onSubmit?: () => void;}

function ForgotPassword2fa({ onSubmit }: ForgotPassword2faProps) {
  const { email } = useSelector((state: RootState) => state.app.user);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [resetSignal, setResetSignal] = useState(false);
  const [code, setCode] = useState<string[]>(Array(6).fill(''));

  const handleChange = (newCode: string[]) => {
    setCode(newCode);
  };

  const handleResend = () => {
    forgotPasswordSMS({ email }); 
    setIsResendDisabled(true);
    setResetSignal(true);  
    setTimeout(() => setResetSignal(false), 100);  
  };

  const handleTimerComplete = () => {
    setIsResendDisabled(false);
  };

  const submit = async ({ email, code }: { email: string; code: string }) => {
    if (!email) {
      return;
    }
    const res = await forgotPasswordCheckCode({ email, code });
    if ('success' in res && res.success) {
      onSubmit && onSubmit();
    }
  };

  
  return (
    <div className="forgot-password2fa">
      <div className="forgot-password2fa__header">
        The code was sent to your phone number. If you didn&apos;t receive the code, please, try again
      </div>
      <ResendTimer onTimerComplete={handleTimerComplete} resetSignal={resetSignal} />
      <TwoFactorCodeInput code={code} onChange={handleChange} />
      <button
        className="forgot-password2fa__button"
        onClick={handleResend}
        disabled={isResendDisabled}
      >
        Resend Email
      </button>
      <CustomButton
        text="Submit"
        size="xl"
        variant="solid"
        colorScheme="primary"
        disabled={code.some(num => num === '')}
        classes="forgot-password2fa__btn"
        onClick={() => {
          if(email)
            submit({ email: email, code: code.join('') });
        }}
      />
    </div>
  );
}

export default ForgotPassword2fa;
