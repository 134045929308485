import { setUserEmail, setUserId } from 'store/slices/appSlice';
import { validateEmail, validatePassword } from 'utils/validations';
import { CustomButton } from 'components/Buttons';
import LabeledInput from 'components/LabeledInput';
import { register } from 'api/userApi';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import './RegisterForm.scss'; 

interface RegisterFormProps {
  onSubmit?: () => void;
}

function RegisterForm({ onSubmit }: RegisterFormProps) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    name: ''
  });

  const handleSubmit = async () => {
    let valid = true;
    const newErrors = { email: '', password: '', confirmPassword: '', name: '' };

    if (!validateEmail(email)) {
      newErrors.email = 'Incorrect email. Please try again.';
      valid = false;
    }

    if (!validatePassword(password)) {
      newErrors.password = 'Password must be at least 6 characters long, contain one uppercase letter, one lowercase letter, and one digit';
      valid = false;
    }

    if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
      valid = false;
    }
    if(name.length < 1) {
      newErrors.name = 'Name is required';
      valid = false;
    }

    setErrors(newErrors);

    if (valid && onSubmit) {
      const res = await register({ email, password, name });
  
      if('user_id' in res) { 
        dispatch(setUserId(res.user_id)); 
        dispatch(setUserEmail(email));
        onSubmit();
      } else {
        setErrors({ 
          email: 'Email is already in use',
          password: '', 
          confirmPassword: '',
          name: ''
        });
      }
    }
  };

  return (
    <div className="register-form">
      <LabeledInput
        page="sign-up"
        type="name"
        text={name}
        error={errors.name}
        onChange={(text) => {
          setName(text);
          setErrors((prevErrors) => ({ ...prevErrors, name: '' }));
        }}
      />

      <LabeledInput
        page="sign-up"
        type="email"
        text={email}
        error={errors.email}
        onChange={(text) => {
          setEmail(text);
          setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
        }}
      />
    
      <LabeledInput
        page="sign-up"
        type="password"
        text={password}
        error={errors.password}
        onChange={(text) => {
          setPassword(text);
          setErrors((prevErrors) => ({ ...prevErrors, password: '' }));
        }}
      />
      <LabeledInput
        page="sign-up"
        type="confirmPass"
        text={confirmPassword}
        error={errors.confirmPassword}
        onChange={(text) => {
          setConfirmPassword(text);
          setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: '' }));
        }}
      />
      <CustomButton
        text="Send Request"
        size="xl"
        variant="solid"
        colorScheme="primary"
        classes="register-form__btn"
        onClick={handleSubmit}
      />

      <div className="login-form__footer">
        <span>Already have an account?</span>
        <a className="login-form__footer__link" href="/log-in">Log in</a>
      </div>
    </div>
  );
}

export default RegisterForm;
