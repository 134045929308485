import { ReactComponent as ArrowDown } from '../../assets/images/dropdown-arrow.svg';
import { useState } from 'react';
import './CardFormDropdown.scss';

interface CardFormDropdownProps {
  page?: string; 
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: 'Male' | 'Female' | '') => void;
}

function CardFormDropdown({ value, onChange }: CardFormDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: 'Male' | 'Female' | '') => {
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className="card-form-dropdown">
      <div className="card-form-dropdown__label">Sex <span>*</span></div>
      <div className="card-form-dropdown__custom-select" onClick={handleDropdownToggle} style={{ background: value ? '#D8D8FE' : '' }}>
        <div className="card-form-dropdown__selected-value" >
          {value || ''}
        </div>
        <ArrowDown />
        {isOpen && (
          <div className="card-form-dropdown__options"> 
            <div className="card-form-dropdown__option" onClick={() => handleOptionClick('Male')}>
              Male
            </div>
            <div className="card-form-dropdown__option" onClick={() => handleOptionClick('Female')}>
              Female
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CardFormDropdown;
