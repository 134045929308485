import { ReactComponent as ArrowDown } from '../../assets/images/closed-arrow.svg';
import { ReactComponent as ArrowUp } from '../../assets/images/opened-arrow.svg';
import { useState } from 'react';
import './FaqListItem.scss';

interface FaqListItemProps { 
  open: boolean;
  question: string;
  answer: string;
}

function FaqListItem({ open, question, answer }: FaqListItemProps) {
  const [isOpen, setIsOpen] = useState(open);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-list-item">
      <div className="faq-list-item__question" onClick={toggleOpen}>
        {question}
        <span className="faq-list-item__icon">{isOpen ? <ArrowUp />: <ArrowDown />}</span>
      </div>
      {isOpen && <div className={`faq-list-item__answer ${isOpen && 'opened'}`}>{answer}</div>}
    </div>
  );
}

export default FaqListItem;
