export const cardFormInputs = {
  patientId: {
    label: 'Patient ID',
    selector: false  
  },
  age: {
    label: 'Age',
    extraIcon: null,
    selector: true  
  },
  complaint: {
    label: 'Main Complaint',
    extraIcon: null,
    selector: false  
  },
  weight: {
    label: 'Weight, kg',
    selector: false  
  },
  height: {
    label: 'Height, cm',
    selector: false  
  }
};

export const cardFromTextareas = {
  complaint: {
    label: 'Chief Complaint',
    required: true,
    rows: 2,
    placeholder: 'Main reason for the visit. Include the onset, location, duration, and nature of the issue.'
  },
  illnessHistory: {
    label: 'History of Present Illness',
    required: true,
    rows: 2,
    placeholder: 'Describe the progression of symptoms: onset, duration, intensity, aggravating/relieving factors, associated symptoms.'
  },
  medicalHistory:{
    label: 'Past Medical History',
    required: true,
    rows: 2,
    placeholder: 'Chronic conditions, past hospitalizations, surgeries, and significant medical procedures.'
  },
  medications:{
    label: 'Medications',
    required: true,
    rows: 2,
    placeholder: 'List all medications with dosage and frequency, including prescriptions, over-the-counter, and supplements.'
  },
  allergies:{
    label: 'Allergies',
    required: true,
    rows: 2,
    placeholder: 'Document any known allergies to drugs, foods, or environmental factors.'
  },
  socialHistory:{
    label: 'Social History',
    required: true,
    rows: 5,
    placeholder: 'Smoking: [Current, Former, Never] Alcohol Use: [Frequency and amount] Recreational Drugs: [Specify substances if applicable] Diet: [Typical diet habits] Exercise: [Frequency and type of physical activity]'
  },
  familyHistory:{
    label: 'Family History',
    required: true,
    rows: 2,
    placeholder: 'Note any significant family medical history, especially related to the patient`s symptoms or chronic diseases.'
  },
  systemsReview:{
    label: 'Review of Systems',
    required: true,
    rows: 3,
    placeholder: 'General: [Any recent changes in weight, fever, fatigue, etc.] Specific Systems: [Review of symptoms by system: cardiovascular, respiratory, gastrointestinal, neurological, etc.]'
  },
  physicalExam:{
    label: 'Physical Examination',
    required: true,
    rows: 4,
    placeholder: 'General Appearance: [Overall observations including behavior, posture, etc.] Vital Signs: [BP, HR, RR, Temp, and other relevant metrics.] Findings: [Examination details focusing on areas relevant to the complaint and other systems.]'
  },
  labResults:{
    label: 'Lab and Imaging Results',
    required: true,
    rows: 2,
    placeholder: 'Results of any recent lab work or imaging studies.'
  },
  additionalNotes:{
    label: 'Additional Concerns/Notes',
    required: true,
    rows: 11,
    placeholder: 'Other information relevant to the patient`s care, including patient concerns, questions, or other relevant details.'
  }
};

export const labeledInputs = {
  email: {
    label: 'Email',
    extraIcon: false 
  },
  password: {
    label: 'Password',
    extraIcon: true
  },
  confirmPass: {
    label: 'Confirm password',
    extraIcon: true
  },
  forgotPass: {
    label: 'Please enter the phone you used while registration',
    extraIcon: false  
  },
  phone: {
    label: 'Phone number',
    extraIcon: false 
  },
  phoneRecover : {
    label: 'Phone',
    extraIcon: false  
  },
  code: {
    label: 'Enter code',
    extraIcon: false  
  },
  newPass: {
    label: 'Please enter your new password',
    extraIcon: false  
  },
  name: {
    label: 'Name',
    extraIcon: false  
  }
};

export const cardFields ={
  'Patient ID': {
    units: '',
  },
  'Age:': { 
    units: '',
  },
  'Weight:': {
    units: ' kg',
  },
  'Height:': {
    units: ' cm',
  },
  'Sex:': {
    units: '',
  },
  'Chief Complaint:': {
    units: '',
  },
  'Patient ID:': {
    units: '',
  },
};
