export const statusTextMap: StatusTextMap = {
  default: 'Processing patient data...',
  problemList: 'Analyzing patient\'s problem list...',
  translate: 'Translating patient data to medical language...',
  summary: 'Summarizing patient\'s medical information...',
  differentialDiagnosis: 'Generating differential diagnosis...',
  suggestions: 'Preparing suggestions for further tests...',
  generatedInfo: 'Processing generated information...'
};

export const initialState = {
  patientId: '',
  age: '',
  gender: '',
  complaint: '',
  weight: '',
  height: '',
  illnessHistory: '',
  medicalHistory: '',
  medications: '',
  allergies: '',
  socialHistory: '',
  familyHistory: '',
  systemsReview: '',
  physicalExam: '',
  labResults: '',
  additionalNotes: ''
};
