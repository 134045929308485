import { AppDispatch, RootState } from 'store';
import { Loader, PatientCreateTab, PatientEditTab, PatientViewTab } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { getChats } from 'store/slices/appSlice';
import { useEffect } from 'react'; 
import './MainPageContent.scss';

function MainPageContent() {
  const dispatch: AppDispatch = useDispatch();
  const { currentTab, loadingPage } = useSelector((state: RootState) => state.app);
  const { user_id } = useSelector((state: RootState) => state.app.user);
 
  useEffect(() => {
    user_id &&
      dispatch(getChats(user_id));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ]);
 

  const renderpage = (tab: string) => {
    switch (tab) {
    case 'create' :
      return(
        <PatientCreateTab />
      );
    case 'edit' :
      return(
        <PatientEditTab />
      );
    case 'view' :
      return (
        <PatientViewTab />
      );
    default:
      return (
        null
      );
    }
  };

  return (
    <div className="main-page-content">
      {
        loadingPage ?
          (
            <Loader />
          ) : (
            renderpage(currentTab)
          )
      }
    </div>
  );
}

export default MainPageContent;
