
import { clearData, setCurrentTab, setSaveModal } from 'store/slices/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { ReactComponent as CreateIcon } from 'assets/images/clipboard-plus.svg';
import { CustomButton } from 'components/Buttons';
import { ReactComponent as FAQIcon} from 'assets/images/help.svg';
import { ReactComponent as LogOutIcon } from 'assets/images/file-output.svg';
import { ReactComponent as LogoBig } from 'assets/images/logo-big.svg'; 
import { RootState } from 'store'; 
import SearchInput from 'components/SearchInput';
import SidebarList from 'components/SidebarList';
import { WarningBlock } from 'components'; 
import { useNavigate } from 'react-router-dom';
import './Sidebar.scss';
 
 
function Sidebar( ) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [error, setError] = useState(false);
  const { chats, blockNavigation, pageBlock, activeChat, user } = useSelector((state: RootState) => state.app);
  const [ selectedCard, setSelectedCard ] = useState <number | null>(activeChat?.id || null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isMouseOver, setIsMouseOver] = useState(false);

  useEffect(() => {
    const handleMouseMove = (event: { clientX: number; clientY: number }) => {
      setPosition({ x: event.clientX - 21, y: event.clientY + 21 });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  const handleCreateNewCase = () => {
    dispatch(setSaveModal(true));
    if(blockNavigation) return;
    dispatch(setCurrentTab('create'));
  };

  const logOut = () => {
    window.location.href = '/log-in';
    Cookies.remove('access_token');
    dispatch(clearData());
  }; 
  
  return (
    <div className="sidebar" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {pageBlock && isMouseOver && (
        <WarningBlock styles={{ left: position.x, top: position.y }} />
      )}
      <div className="sidebar__header">
        <LogoBig/> 
      </div>
      <SearchInput
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        error={error}
      />
      {
        user?.credits ? 
          (
            <div className="sidebar__credits">
              <div className="sidebar__credits__text">
            Remaining cases:
              </div>
              <div className={`sidebar__credits__value ${user.credits <= 10 ? 'low-credits' : ''}`}>
                {user.credits}
              </div>
            </div>
          )
          : 
          ( 
            <div className="sidebar__credits">
              <div className="sidebar__credits__text">
          Remaining cases:
              </div>
              <div className={`sidebar__credits__value ${user.credits <= 10 ? 'no-credits' : ''}`}>
              0 left
              </div>
            </div>
          )
      }
      <CustomButton
        text="Create New Case"
        size="xl"
        variant="solid"
        colorScheme="primary"
        classes="sidebar__btn-create"
        rightIcon={<CreateIcon/>}
        disabled={!!pageBlock || !user.credits}
        onClick={handleCreateNewCase}
      />
      <div className="sidebar__list__cont">
        <SidebarList 
          searchValue={searchValue}
          patientCards={chats?.length ? chats : []}
          activeCard={activeChat?.id || selectedCard || null}
          setSelectedCard={setSelectedCard}
          setError={setError}
        />
      </div>
      <div className="sidebar__footer">
        <div className="sidebar__footer__faq" onClick={()=> {
          if(pageBlock) return;
          navigate('/faq');
        }}>
          <FAQIcon/>
          <div className="sidebar__footer__faq__text">
          FAQ
          </div>
        </div>
        <div className="sidebar__footer__logout" onClick={logOut}>
          <LogOutIcon/>
          <div className="sidebar__footer__logout__text">
            Log out
          </div>
        </div> 
      </div>
    </div>
  );
}

export default Sidebar;
