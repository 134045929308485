import { CustomButton } from 'components/Buttons';
import './PasswordResetSuccess.scss';
 

function PasswordResetSuccess({text}: {text: string}) {
  
  return (
    <div className="password-reset-success">
      <CustomButton
        text={text}
        size="xl"
        variant="solid"
        colorScheme="primary"
        classes="password-reset-success__btn"
        onClick={() => {
          window.location.href = '/login';
        }}
      />
    </div>
  );
}

export default PasswordResetSuccess;
