import { RootState } from 'store';
import { useSelector } from 'react-redux';
import './WarningBlock.scss';

 interface WarningBlockProps {
  styles?: React.CSSProperties;
}
function WarningBlock({ styles }: WarningBlockProps) {
  const { pageBlock } = useSelector((state: RootState) => state.app);
  return (
    <div className="warning-block" style={styles}>
      <div className="warning-block__header">Please Wait!</div>
      <div className="warning-block__text">{pageBlock}</div>
    </div>
  );
}

export default WarningBlock;
