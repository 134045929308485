import Cookies from 'js-cookie';
import axios from 'axios';
import { handleError } from '../utils/handleError';

const API = `${process.env.REACT_APP_API_URL}`;

axios.defaults.withCredentials = true;
const accessToken = Cookies.get('access_token');


export const createPatientApi = async (data: CreateChat) => {
  try {
    const res = await axios.post(`${API}/api/chat`, data);
    return res.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getChatsApi = async (user_id: string): Promise<Patient_card[] | ErrorResponse> => {
  try {
    const res = await axios.get(`${API}/api/chat/${user_id}`, {
      headers: {
        accept: 'application/json, text/plain, */*',
        cookie: `access_token=${accessToken}`,
      },
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return handleError(error);
  }
};

export const getOneChatApi = async (chat_id: number): Promise<Patient_card[] | ErrorResponse> => {
  try {
    const res = await axios.get(`${API}/api/chat/one/${chat_id}`, {
      headers: {
        accept: 'application/json, text/plain, */*',
        cookie: `access_token=${accessToken}`,
      },
      withCredentials: true,
    });
    return res.data;
  } catch (error) {
    return handleError(error);
  }
};

export const updatePatientApi = async (data: UpdateChat) : Promise <UpdateChatResponse | ErrorResponse> => {
  try {
    const res = await axios.patch(`${API}/api/chat/update-card`, data);
    return res.data;
  } catch (error) {
    return handleError(error);
  }
};

export const deletePatientApi = async (data: DeletePatient) => {
  try {
    const res = await axios.delete(`${API}/api/chat/${data.id}`);
    return res.data;
  } catch (error) {
    return handleError(error);
  }
};
