import axios from 'axios';
import { handleError } from '../utils/handleError';

const API = `${process.env.REACT_APP_API_URL}`;
 
export const register = async ({ email, password, name }: RegisterInput): Promise<RegisterResponse | ErrorResponse> => {
  try {
    const res = await axios.post<RegisterResponse>(`${API}/api/auth/register`, { email, password, name });
    return {user_id: res.data.user_id, success: true};
  } catch (error) {
    return handleError(error);
  }
};

export const login = async ({ email, password }: LoginInput): Promise<SuccessResponse | ErrorResponse> => {
  try {
    const res = await axios.post(`${API}/api/auth/login`, { email, password });
    if (res.status === 201) {
      return { success: true };
    }
    return handleError(new Error('Unexpected status code'));
  } catch (error) {
    return handleError(error);
  }
};

export const registerSendCode = async ({user_id, email} : RegisterSendCode): Promise<SuccessResponse | ErrorResponse> => {
  try {
    const res = await axios.post(`${API}/api/auth/send-code`, { user_id, email });
    if (res.status === 201) {
      return { success: true };
    }
    return handleError(new Error('Unexpected status code'));
  } catch (error) {
    return handleError(error);
  }
};

export const checkCode = async ({email, code} : CheckCode): Promise<SuccessCodeResponse | ErrorResponse> => {
  try {
    const res = await axios.post(`${API}/api/auth/check-code`, { email, code },
      {
        headers: {
          accept: 'application/json, text/plain, */*',
        },
      }
    );
    if (res.status === 201) {
      return { success: true, email: res.data.email, user_id: res.data.user_id, token: res.data.token};
    }
    return handleError(new Error('Unexpected status code'));
  } catch (error) {
    return handleError(error);
  }
};

export const forgotPasswordSMS = async ({email} : ForgotPass): Promise<SuccessResponse | ErrorResponse> => {
  try {
    const res = await axios.post(`${API}/api/auth/forgot-pass-sms`, { email });
    if (res.status === 201) {
      return { success: true };
    }
    return handleError(new Error('Unexpected status code'));
  } catch (error) {
    return handleError(error);
  }
};

export const forgotPasswordCheckCode = async ({email, code} : CheckCodeForgot): Promise<SuccessResponse | ErrorResponse> => {
  try {
    const res = await axios.post(`${API}/api/auth/forgot-pass-check`, { email, code });
    if (res.status === 201) {
      return { success: true };
    }
    return handleError(new Error('Unexpected status code'));
  } catch (error) {
    return handleError(error);
  }
};

export const forgotPasswordNewPass = async ({email, password} : NewPass ): Promise<SuccessResponse | ErrorResponse> => {
  try {
    const res = await axios.post(`${API}/api/auth/change-pass`, { email, password });
    if (res.status === 201) {
      return { success: true };
    }
    return handleError(new Error('Unexpected status code'));
  } catch (error) {
    return handleError(error);
  }
};
 
export const confirmPhone = async ({user_id, phone, code} : ConfirmPhone): Promise<SuccessResponse | ErrorResponse> => {
  try {
    const res = await axios.post(`${API}/api/user/confirm-phone`, { user_id, phone, code });
    if (res.status === 201) {
      return { success: true };
    }
    return handleError(new Error('Unexpected status code'));
  } catch (error) {
    return handleError(error);
  }
};

 
export const getUser = async (id: string): Promise<UserData | ErrorResponse> => {
  try {
    const res = await axios.get(`${API}/api/user/${id}`);
    return res.data;
  } catch (error) {
    return handleError(error);
  }
};

    
