import './CardBlock.scss';

interface CardBlockProps { 
  type: string;
  value: string
}

function CardBlock({ 
  type,
  value
}: CardBlockProps) {
  
  return (
    <div className="card-block">
      <div className="card-block__label">{type}</div>
      <div className="card-block__value">{value}</div>
    </div>
  );
}

export default CardBlock;
