import { ForwardedRef, forwardRef } from 'react';
import { CustomButton } from 'components/Buttons';
import './SaveModal.scss';

interface SaveModalProps { 
  onConfirm?: () => void;
  onCancel: () => void;
}

const SaveModal = forwardRef((
  { onConfirm, onCancel }: SaveModalProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <div className="save-modal" 
      ref={ref}
      tabIndex={-1}>
      <div className="delete-modal__title">
      Save Changes
      </div>

      <div className="delete-modal__content">
      You have unsaved changes in the current patient case. Would you like to save your changes before navigating to another case?
      </div>
      <div className="delete-modal__buttons">
        <CustomButton
          text="No"
          size="sm"
          variant="ghost"
          colorScheme="secondary"
          onClick={onCancel}
        />
        <CustomButton
          text="Save"
          size="sm"
          variant="solid"
          colorScheme="success"
          onClick={onConfirm}
        />
      </div>

    </div>
  );
});

export default SaveModal;
