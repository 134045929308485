import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import CardBlock from 'components/CardBlock';
import CardField from 'components/CardField';
import CardList from 'components/CardList';
import Chat from 'components/Chat';
import { CustomButton } from 'components/Buttons';
import { ReactComponent as DropdownArrow } from 'assets/images/dropdown-arrow.svg';
import { RootState } from 'store';
import { setCurrentTab } from 'store/slices/appSlice'; 
import './PatientViewTab.scss';

function PatientViewTab() {
  const dispatch = useDispatch();
  const { activeChat, pageBlock } = useSelector((state: RootState) => state.app);
  const [ fullcard, setFullCard ] = useState(false);
  const contentBlockRef =useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (contentBlockRef.current) {
        setHeight(contentBlockRef.current.clientHeight);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (contentBlockRef.current) {
      resizeObserver.observe(contentBlockRef.current);
    }

    handleResize(); 

    return () => {
      if (contentBlockRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(contentBlockRef.current);
      }
    };
  }, [contentBlockRef]);

  return (
    <div className="patient-view-tab">
      <div className="patient-view-tab__top">
        <div className="patient-view-tab__top__header">
          <div>Patient Card </div>
          {
            (!pageBlock && !fullcard) &&
            <CustomButton 
              size="sm"
              text="Edit" 
              style={{ background: '#FFF', color: '#050505', borderColor: '#FFF'}}
              onClick={() => {
                dispatch(setCurrentTab('edit'));
              }}
            />
          }
        </div>
        <div className={`patient-view-tab__top__content ${fullcard ? 'fullcard' : ''}`} ref={contentBlockRef} >
          {
            fullcard ?
              (
                <>
                  <div className="patient-view-tab__top__content__block expanded" >
                    <CardField type="Patient ID:" value={activeChat?.patientId|| ''} />
                    <CardField type="Age:" value={activeChat?.age || ''} />
                    <CardField type="Sex:" value={activeChat?.gender || ''} />
                    <CardField type="Weight:" value={activeChat?.weight || ''} />
                    <CardField type="Height:" value={activeChat?.height || ''} />
                    <CardField type="Chief Complaint:" value={activeChat?.complaint || ''} />
                    <CardList type="History of Present Illness:" list={activeChat?.illnessHistory || []} />
                    <CardList type="Past Medical History:" list={activeChat?.medicalHistory || []} />
                    <CardList type="Medications:" list={activeChat?.medications || []} />
                    <CardList type="Allergies:" list={activeChat?.allergies || []} />
                  </div>
                  <div className="patient-view-tab__top__content__block">
                    <CardBlock type="Social History" value={activeChat?.socialHistory || ''} />
                    <CardBlock type="Family History" value={activeChat?.familyHistory || ''} />
                    <CardBlock type="Review of Systems" value={activeChat?.systemsReview || ''} />
                    <CardBlock type="Physical Examination" value={activeChat?.physicalExam || ''} />
                    <CardBlock type="Lab and Imaging Results" value={activeChat?.labResults || ''} />
                    <CardBlock type="Additional Concerns/Notes" value={activeChat?.additionalNotes || ''} />
                  </div>
                 
                  <div className="patient-view-tab__top__dropdown reversed" onClick={() => setFullCard(false)}>
                    <DropdownArrow />
                  </div>
                </>
              ) : (
                <>
                  <div className="patient-view-tab__top__content__block" >
                    <CardField type="Patient ID:" value={activeChat?.patientId|| ''} />
                    <CardField type="Age:" value={activeChat?.age || ''} />
                    <CardField type="Sex:" value={activeChat?.gender || ''} />
                    <CardField type="Weight:" value={activeChat?.weight || ''} />
                    <CardField type="Height:" value={activeChat?.height || ''} />
                    <CardField type="Chief Complaint:" value={activeChat?.complaint || ''} />
                  </div>
                  <div className="patient-view-tab__top__content__block">
                    <CardList type="History of Present Illness:" list={activeChat?.illnessHistory || []} />
                    <CardList type="Past Medical History:" list={activeChat?.medicalHistory || []} />
                  </div>
                  <div className="patient-view-tab__top__content__block">
                    <CardList type="Medications:" list={activeChat?.medications || []} />
                    <CardList type="Allergies:" list={activeChat?.allergies || []} />
                  </div>
                  <div className="patient-view-tab__top__dropdown " onClick={() => setFullCard(true)}>
                    <DropdownArrow />
                  </div>
                </>
              )
          }
        
        </div>
      </div>
      <div className="patient-view-tab__bottom">
        <Chat topBlockHeight={height} fullCard={fullcard}/>
      </div>
    </div>
  );
}

export default PatientViewTab;
