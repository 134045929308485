import { AppDispatch, RootState } from 'store';
import { addMessage, removeRegenerate, replaceLastMessage, replaceMessages, setPageBlock, setStatus } from 'store/slices/appSlice';
import { useDispatch, useSelector } from 'react-redux'; 
import { useEffect, useState } from 'react';
import ChatInput from 'components/ChatInput';
import ChatList from 'components/ChatList'; 
import { statusTextMap } from 'utils';
import { useSocket } from 'App';
import { warning } from 'data/userMessage';
import './Chat.scss';

interface ChatProps {
  topBlockHeight: number;
  fullCard: boolean;
}

function Chat({ topBlockHeight, fullCard }: ChatProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { activeChat } = useSelector((state: RootState) => state.app);
  const socket = useSocket();
  const [ loading, setLoading ] = useState(false);
  const [rows, setRows] = useState(1);

  // eslint-disable-next-line
  const receiveMessage = async (data: any) => { 
    dispatch(addMessage(data)); 
    dispatch(setPageBlock(''));
    setLoading(false);  
  };

  useEffect(() => {
    dispatch(setPageBlock(''));
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  useEffect(() => {
    if(fullCard)
      setRows(1);
  }, [fullCard]);

  const handleGenerateInfo= (id: number) => {
    dispatch(addMessage({
      id: activeChat?.messages && activeChat?.messages?.length + 1 || 0,
      text: statusTextMap['default'],
      chat_id: activeChat?.id,
      role: 'assistant'
    }));
    dispatch(setPageBlock(warning.create)); 
    socket?.emit('generateInfo', {
      chat_id: id,
    });
  };

  const changeStatus = (status: string) => {
    if(!activeChat?.regenerate ){
      dispatch(setStatus(status));
      dispatch(replaceMessages([{
        id: 0,
        text: statusTextMap[status] || status,
        chat_id: activeChat?.id,
        role: 'assistant'
      }]));
    } else{
      dispatch(replaceLastMessage({
        text: statusTextMap[status] || status,
        role: 'assistant'
      }));
    }
  }; 
 

  useEffect(() => {
    if (socket) { 
      if(activeChat?.id && activeChat?.messages?.length === 0 || activeChat?.regenerate ) { 
        handleGenerateInfo(activeChat.id);
      }
      socket.on('newMessage', receiveMessage);
      socket.on('problemList', () => changeStatus('problemList'));
      socket.on('translate', () => changeStatus('translate'));
      socket.on('summary', () => changeStatus('summary'));
      socket.on('differentialDiagnosis', () => changeStatus('differentialDiagnosis'));
      socket.on('suggestions', () => changeStatus('suggestions'));

      socket.on('generatedInfo', 
        (data: string) => {
          dispatch(setPageBlock('')); 
          changeStatus(data);
          dispatch(removeRegenerate());
        }
      );

      return () => {
        socket.off('newMessage');
        socket.off('problemList');
        socket.off('translate');
        socket.off('summary');
        socket.off('differentialDiagnosis');
        socket.off('suggestions');
        socket.off('generatedInfo');  
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const sendMessage = (message: string) => {
    dispatch(addMessage({
      id:  activeChat?.messages && activeChat?.messages?.length + 1 || 0,
      text: message,
      role: 'user'
    }));
  
    socket?.emit('sendMessage', {
      text: message,
      chat_id: activeChat?.id,
    });
    setLoading(true); // Disable the send button
    dispatch(setPageBlock(warning.reply)); 
  };

 
  return (
    <>
      <ChatList
        topBlockHeight={topBlockHeight}
        rows={rows}
      />
      <ChatInput 
        fullCard={fullCard}
        disabled={loading}
        rows={rows}
        setRows={setRows}
        onSend={sendMessage}
      />
    </>
   
  );
}

export default Chat;
