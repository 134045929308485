import { AppDispatch, RootState } from 'store';
import { DeleteModal, ModalWrapper, SidebarErrorBlock, SidebarListItem } from 'components';
import { deletePatient, getChats, getOneChat, setActiveChat, setCurrentTab, setSaveModal } from 'store/slices/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react'; 
import './SidebarList.scss'; 
 
interface SidebarListProps {
  searchValue: string;
  patientCards: Patient_card[];
  activeCard: number | null; 
  // eslint-disable-next-line no-unused-vars
  setSelectedCard: (id: number) => void;
  setError: (err: boolean) => void;
}

function SidebarList({ searchValue, patientCards, activeCard, setSelectedCard, setError }: SidebarListProps) {
  const dispatch = useDispatch<AppDispatch>();
  const { user_id } = useSelector((state: RootState) => state.app.user);
  const { blockNavigation, pageBlock } = useSelector((state: RootState) => state.app);
  const [isModal, setIsModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const onCloseModal = () => {
    setIsModal(false);
    setDeleteId(null);
  };

  const onDelete = async () => {
    if (deleteId !== null) {
      await dispatch(deletePatient({ id: deleteId }));
      await dispatch(getChats(user_id));
      dispatch(setActiveChat(null));
      dispatch(setCurrentTab('create'));
      onCloseModal();
    }
  };

  const searchItemsAvaliable = (searchValue: string, patientCards: Patient_card[]) => {
    return !searchValue || patientCards?.find((patient) => patient.patientId.startsWith(searchValue));
  };

  const displayFilteredItems = (searchValue: string, patientCards: Patient_card[]) => {
    if(!searchValue) return patientCards;
    return patientCards?.filter((patient) => patient.patientId.startsWith(searchValue)) || [];
  };

  useEffect(() => {
    if (!searchItemsAvaliable(searchValue, patientCards)) {
      setError(true);
    } else{
      setError(false);
    }
  }, [searchValue, patientCards, setError]);
  

  const navigateToPatient = (patient: Patient_card) => {
    dispatch(setSaveModal(true));
    if(blockNavigation) return;
    dispatch(getOneChat(patient.id)); 
    dispatch(setCurrentTab('view'));
    setSelectedCard(patient.id);
  };

  return (
    <div className="sidebar-list">
      {
        searchItemsAvaliable(searchValue, patientCards) ?
          displayFilteredItems(searchValue, patientCards)?.map((patient) => (
            <SidebarListItem 
              key={patient.id}
              patient={patient}
              activeCard={activeCard}
              pageBlock={pageBlock}
              navigateToPatient={navigateToPatient}
              setDeleteId={setDeleteId}
              setIsModal={setIsModal}
            />
          ))
          :
          <SidebarErrorBlock />
      }

      {isModal && (
        <ModalWrapper open={isModal} onClose={onCloseModal}>
          <DeleteModal onCancel={onCloseModal} onConfirm={onDelete} /> 
        </ModalWrapper>
      )}
    </div>
  );
}

export default SidebarList;
