export const faqData = [
  {
    open: false,
    question: 'How do I log in to CareAid?',
    answer: 'Navigate to the login page via the designated URL and enter your username and password. If you have two-factor authentication (2FA) enabled, you will also need to enter the one-time code sent to your email.'
  },
  {
    open: false,
    question: 'What is two-factor authentication (2FA) and how do I enable it?',
    answer: 'Two-factor authentication (2FA) adds an extra layer of security to your account by requiring a second form of identification. You can enable it in the account settings under the security section.'
  },
  {
    open: false,
    question: 'How can I view active patient cases?',
    answer: 'To view active patient cases, navigate to the \'Patient Cases\' section in your dashboard. Here you will find a list of all active cases with relevant details.'
  },
  {
    open: false,
    question: 'Where can I find patient cases in the dashboard?',
    answer: 'Patient cases are listed in the sidebar of the dashboard. Click on any case to open the chat and view detailed information.'
  },
  {
    open: false,
    question: 'What information is available on the Patient\'s card in the chat?',
    answer: 'The Patient`s card at the top of the chat includes detailed patient case information, such as Patient ID, age, sex, main complaint and pertinent patient information. These fields are editable.'
  },
  {
    open: false,
    question: 'How can I create a patient case using the Chrome extension?',
    answer: 'Activate the CareAid extension icon while viewing an EMR page. The extension will scan and identify relevant patient data, then auto-fill and submit the patient case form in CareAid.'
  },
  {
    open: false,
    question: 'How do I create a new patient case from the dashboard?',
    answer: 'Click the \'Create New Case\' button on the dashboard. A modal with instructions on how to retrieve data will appear.'
  }
];
  
